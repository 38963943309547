import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IconModule, Origin8Icon } from '../icon';
import { NotificationService } from './notification.service';

@Component({
    selector: 'o8-enable-notification-banner',
    templateUrl: './enable-notification-banner.component.html',
    styleUrls: ['./enable-notification-banner.component.scss'],
    imports: [CommonModule, IconModule]
})
export class EnableNotificationBannerComponent {
  warningIcon: Origin8Icon = Origin8Icon.warning;
  isBrowserNotificationDisabled$ = this.notificationService.isBrowserNotificationDisabled$;

  constructor(private notificationService: NotificationService) {}
}
